@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "DM Sans", sans-serif;
  color: #ffffff;
}

html {
  scroll-behavior: smooth;
}

body {
  background: #010725;
}

/* custom css */
.splide__slide {
  opacity: 0.5;
  transition: all 0.5s;
}

.splide__slide .bg-dark-blue .flex {
  opacity: 0.5;
  transition: all 0.5s;
}

.splide__slide:is(.is-active) {
  opacity: 1;
}

.splide__slide:is(.is-active) .bg-dark-blue .flex {
  opacity: 1;
}

li::marker {
  color: #0328EE;
}